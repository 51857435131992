<template>

    <section class="section container columns is-centered  pt-4 ">
        <div class="column  is-half-desktop  ">
            <div class="field columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Nombre <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column">
                    <label class="label pt-2 ">Nombre <span style="color: red">*</span></label>
                </div>
                <div class="control column is-flex-grow-0">
                    <input v-model="nombre" name="nombre" class="input" type="text">
                    <p v-show="message_error.nombre_error != ''" class="help is-danger">{{message_error.nombre_error}}</p>
                </div>
            </div>

            <div class="field  columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Domicilio</label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2 ">Domicilio</label>
                </div>
                <div class="control column is-flex-grow-0  ">
                    <input class="input " name="domicilio" v-model="domicilio" type="text" >
                </div>
            </div>

            <div class="field columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Ciudad <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2 ">Ciudad <span style="color: red">*</span></label>
                </div>
                <div class="control column is-flex-grow-0 ">
                    <div class="select">
                        <select class="options" v-model="CiudadSeleccionada">
                            <!-- <option :value="selected.id">{{selected.nombre}}</option> -->
                            <option v-for="ciudad in ciudades" :key="ciudad.id" :value="ciudad.id">{{ciudad.nombre}}</option>

                        </select>
                    </div>
                     <p v-show="message_error.ciudad_error != ''" class="help is-danger">{{message_error.ciudad_error}}</p>
                </div>
            </div>
            <!-- <div class="field  columns pt-2 mb-0 has-text-centered">
                <div v-show="!isMobile" class="column   has-text-right mr-3">
                    <label class="label ">Coordenadas</label>
                </div>
                <div v-show="isMobile" class="column  ">
                    <label class="label ">Coordenadas</label>
                </div>
                <div v-show="!isMobile" class="columns pt-1  ">
                    <div class="control column mr-3">
                        <input class="input input2 " name="latitud" v-model="latitud" type="number" >
                        <input class="input input2 ml-1" name="longitud" v-model="longitud"  type="number" >
                        <p  class="help is-grey">Ejemplo: -27.58666 , -58.666</p>
                    </div>
                </div>
                <div v-show="!isMobile" class="columns  pt-1  ">
                    <div  class="column pl-1  mr-3">
                        <a class="button" href="https://www.google.com.ar/maps/place/27%C2%B026'03.6%22S+58%C2%B057'28.6%22W/@-27.442618,-59.0488903,11z/data=!4m6!3m5!1s0x0:0x0!7e2!8m2!3d-27.4343344!4d-58.957942" target="_blank">
                            <i class="fas fa-map-marked-alt"></i>
                        </a>
                    </div>
                </div>
                <div v-show="isMobile" class="columns has-text-center is-mobile pt-1">
                    <div class="control column mr-3">
                        <input class="input input2 " name="latitud" v-model="latitud" type="number" >
                        <input class="input input2 ml-1" name="longitud" v-model="longitud"  type="number" >
                        <p  class="help is-grey">Ejemplo: -27.58666 , -58.666</p>
                    </div>
                </div>
                <div v-show="isMobile" class="columns is-mobile pt-1  ">
                    <div  class="column pl-1  mr-3">
                        <div  class="column pl-1  mr-3">
                                    <a class="button" href="https://www.google.com.ar/maps/place/27%C2%B026'03.6%22S+58%C2%B057'28.6%22W/@-27.442618,-59.0488903,11z/data=!4m6!3m5!1s0x0:0x0!7e2!8m2!3d-27.4343344!4d-58.957942" target="_blank">


                                <i class="fas fa-map-marked-alt"></i>

                                </a>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- <div v-show="message_error.lat_error != ''" class="columns">
                    <div class="column">
                    </div>
                    <div v-show="!isMobile" class="column has-text-left">
                            <p  class="help is-danger ">{{message_error.lat_error}}</p>
                    </div>
                    <div  v-show="isMobile" class="column has-text-centered">
                            <p  class="help is-danger ">{{message_error.lat_error}}</p>
                    </div>
            </div> -->

            <div class="field  columns has-text-centered">
            <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Precio por día <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2 ">Precio por día <span style="color: red">*</span></label>
                </div>
                <div class="control column is-flex-grow-0  ">
                    <input v-model="precio" name="precio" class="input" type="number">
                    <p v-show="message_error.precio_error != ''" class="help is-danger">{{message_error.precio_error}}</p>
                </div>
            </div>

            <div class="field columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Comentario</label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2 ">Comentario</label>
                </div>
                <div class="control column   is-flex-grow-0 ">
                    <textarea class="textarea " name="Comentario" v-model="comentario" placeholder="Escriba un comentario"></textarea>
                </div>
            </div>

            <div v-show="!isMobile" class="field columns has-text-centered">
                <div class="column  has-text-right ">
                    <button class="button   " style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>
                <div v-show="!isMobile" class="control column   is-flex-grow-0 ">
                    <button v-show="!cargando" class="button  is-success " style="width: 17.1rem;" @click="validar">
                        Guardar punto wifi
                    </button>
                    <button v-show="cargando" class="button is-success is-loading" style="width: 17.1rem;">Guardar punto wifi</button>
                </div>
            </div>
            <div v-show="isMobile" class="field columns has-text-centered" @click="validar">
                <div class="control column   is-flex-grow-0 ">
                    <button v-show="!cargando" class="button is-success" style="width: 17.1rem;">
                        Guardar punto wifi
                    </button>
                    <button v-show="cargando" class="button is-success is-loading" style="width: 17.1rem;">Guardar punto wifi</button>
                </div>
                <div class="column   ">
                    <button class="button mr-2  " style=" background-color: #DD2923 ; color:white;" @click="volver">
                            Cancelar
                    </button>
                </div>
            </div>
        </div>

        <div>
            <div class="modal" :class="{'is-active': mostrar_error}">
                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}" >
                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->

                        <p class="has-text-centered has-text-danger">No se completaron los campos requeridos</p>

                        <div class="columns mt-2">
                            <div class="column">
                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_error = false">Esta bien</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { GraphQLClient } from 'graphql-request'
import { useRoute, useRouter } from 'vue-router'
import { inject, ref } from 'vue'
import store from '@/store';
import {queryMe} from '@/composables/queryMe.js'


export default {

name:'EditarPuntoWifi',

    created () {
        // console.log(this.$route.params)
        this.traerPuntoWifi()
        this.traerCiudades()
    },

    setup(){
        const isMobile = inject('isMobile')
        const error = ref(false)
        const message_error = ref([
            {nombre_error: '', precio_error: ''}
        ])
        const nombre = ref('')
        const id = ref(null)
        const precio = ref(null)
        const domicilio = ref('')
        const latitud = ref(null)
        const longitud = ref(null)
        const comentario = ref('')
        const CorreoContacto = ref('')
        const selected = ref({id: null , nombre:'', lat_error: '', ciudad_error: ''})
        const ciudades = ref([])
        const CiudadSeleccionada = ref(null)
        const router = useRouter()
        const route = useRoute()
        const endpoint = store.state.url_backend
        const cargando = ref(false)
        const mostrar_error = ref(false)

        const validar = async () => {
            cargando.value = true
            let habilitado = await queryMe()
            if (habilitado) {
                validando()
            } else {
                router.push({name: 'Login'})
            }
        }

        const validando = () => {
            error.value = false
            message_error.value.nombre_error = ''
            message_error.value.precio_error = ''
            message_error.value.ciudad_error = ''
            // message_error.value.lat_error = ''

            if (nombre.value == '') {
                error.value = true
                message_error.value.nombre_error = 'El campo nombre no puede estar vacio'
            }
            if (!precio.value) {
                error.value = true
                message_error.value.precio_error = 'El campo precio no puede estar vacio'
            }
            if (precio.value < 0) {
               error.value = true
               message_error.value.precio_error = 'El campo precio no puede ser negativo'
            }
            if (CiudadSeleccionada.value == null) {
                error.value = true
                message_error.value.ciudad_error = 'Se debe seleccionar una ciudad'
            }

            // if (latitud.value == null || longitud.value == null || latitud.value == '' || longitud.value == ''){
            //     console.log('gola')
            //     message_error.value.lat_error = 'latitud y longitud requerida'
            //     console.log(message_error.value.lat_error)

            // }

            // if ((latitud.value > 180) || (latitud.value < -180)) {
            //     error.value = true
            //     message_error.value.lat_error = 'Debe de estar entre un rango de 180 a -180'
            // }
            // if ((longitud.value > 180) || (longitud.value < -180)) {
            //     error.value = true
            //     message_error.value.lat_error = 'Debe de estar entre un rango de 180 a -180'
            // }

            if (error.value) {
                cargando.value = false
                mostrar_error.value = true
            } else {
                editar()
            }
        }

        const traerPuntoWifi = () => {
            const client = new GraphQLClient(endpoint)
            client.rawRequest(/* GraphQL */ `
                query($id: ID){
                    puntowifi(id: $id){
                        id,
                        nombre,
                        domicilio,
                        # latitud,
                        # longitud,
                        precioxdia,
                        comentario,
                        municipio{
                            id,
                            nombre
                        }
                    }
                }`,
                {
                    id : route.params.id
                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
            .then((data) => {
                // console.log(data.data.puntowifi)
                // Asignamos los valores a las variables de data para pasarlos y poder editarlos en editar()
                let puntowifi = data.data.puntowifi
                id.value = puntowifi.id
                nombre.value = puntowifi.nombre
                domicilio.value = puntowifi.domicilio
                // latitud.value = puntowifi.latitud
                // longitud.value = puntowifi.longitud
                comentario.value = puntowifi.comentario
                selected.value.id= puntowifi.municipio.id
                selected.value.nombre= puntowifi.municipio.nombre
                precio.value = puntowifi.precioxdia
                // CiudadSeleccionada = puntowifi.municipio.nombre
                CiudadSeleccionada.value = puntowifi.municipio.id


            }).catch(error => {
                // console.log(error.response);

            })
        }


        const traerCiudades = () => {

            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
            query{
                municipios(first: 100){
                    data{
                        id
                        nombre,
                    }
                }
            }`,
            {
                //variables
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                // console.log(data)

                data.data.municipios.data.forEach(element => {
                    ciudades.value.push({id: element.id ,nombre: element.nombre})
                    CiudadSeleccionada.value = element.id
                });

            }).catch(error => {
            //   console.log(error.response);
            })

        }

        const editar = () => {

            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

        //    longitud.value= parseFloat(longitud.value)
        //    latitud.value= parseFloat(latitud.value)
           precio.value = parseFloat(precio.value)

            // Estructura FetchQL(url, query, variable, opcions)
            client.rawRequest(/* GraphQL */ `
            mutation($id:ID!,$nombre:String!, $domicilio:String! , $precioxdia:Float!, $comentario:String!, $municipio_id:ID){
                modificaPuntowifi(id: $id, input:{
                        nombre: $nombre,
                        domicilio: $domicilio,
                        # latitud: $latitud,
                        # longitud: $longitud,
                        precioxdia:$precioxdia,
                        comentario:$comentario,
                        municipio_id:$municipio_id
                    }){
                        id,
                        nombre,
                        domicilio,
                        # latitud,
                        # longitud,
                        precioxdia,
                        comentario,

                    }
            }`,
            {
                nombre: nombre.value,
                domicilio: domicilio.value,
                // latitud: latitud.value,
                // longitud: longitud.value,
                precioxdia: precio.value,
                // CorreoContacto: CorreoContacto,
                comentario: comentario.value,
                municipio_id: CiudadSeleccionada.value,
                id:id.value

            },
            {

                authorization: `Bearer ${ localStorage.getItem('user_token') }`

            })
            .then((data) => {
                // console.log('guarde')
                // console.log(data)
                router.push({name: 'TableroPuntoWifi'})
                let accion = "edicionpuntowifi"
                store.commit('verificar_carga',accion)

            }).catch(error => {
            //   console.log(error.response);

            })
        }




        const volver = () => {
            router.go(-1)
        }
         return{

            isMobile,
            error ,
            message_error ,
            nombre ,
            id ,
            precio ,
            domicilio ,
            latitud ,
            longitud ,
            comentario,
            CorreoContacto ,
            selected ,
            ciudades,
            CiudadSeleccionada  ,
            traerCiudades,
            traerPuntoWifi,
            validar,
            volver,
            editar,
            // endpoint,
            cargando,
            mostrar_error
        }
    }
}
</script>

<style scoped>

.input{

  width: 17.1rem;

}
.input2{

    width: 6.7rem;

}
.options{
        width: 17.1rem;
}

.select{

    width: 17.1rem;

}

.textarea{

    width: 17.1rem;
}
</style>